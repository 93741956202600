import ResearchImage from "../../../../src/components/research/research-image";
import InequalitiesSpendingDesktop from "../../../../src/content/further-education/svg/further-educations-inequalities-in-funding-desktop.svg";
import InequalitiesSpendingMobile from "../../../../src/content/further-education/svg/further-educations-inequalities-in-funding-mobile.svg";
import InequalitiesSpendingTablet from "../../../../src/content/further-education/svg/further-educations-inequalities-in-funding-tablet.svg";
import ResizableGraph from "../../../../src/components/research/resizable-graph";
import GraphLegend from "../../../../src/components/research/graph-legend";
import * as React from 'react';
export default {
  ResearchImage,
  InequalitiesSpendingDesktop,
  InequalitiesSpendingMobile,
  InequalitiesSpendingTablet,
  ResizableGraph,
  GraphLegend,
  React
};