// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activity-map-tsx": () => import("./../../../src/pages/activity-map.tsx" /* webpackChunkName: "component---src-pages-activity-map-tsx" */),
  "component---src-pages-best-practice-tsx": () => import("./../../../src/pages/best-practice.tsx" /* webpackChunkName: "component---src-pages-best-practice-tsx" */),
  "component---src-pages-get-involved-tsx": () => import("./../../../src/pages/get-involved.tsx" /* webpackChunkName: "component---src-pages-get-involved-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-life-stage-research-tsx": () => import("./../../../src/pages/life-stage-research.tsx" /* webpackChunkName: "component---src-pages-life-stage-research-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-life-stage-tsx": () => import("./../../../src/templates/life-stage.tsx" /* webpackChunkName: "component---src-templates-life-stage-tsx" */)
}

