import QualityOfSchoolingSVGMobile from "../../../../src/content/school-years/svg/school-years-type-and-quality-of-schooling-mobile.svg";
import QualityOfSchoolingSVGTable from "../../../../src/content/school-years/svg/school-years-type-and-quality-of-schooling-tablet.svg";
import QualityOfSchoolingSVGDesktop from "../../../../src/content/school-years/svg/school-years-type-and-quality-of-schooling-desktop.svg";
import ResizableGraph from "../../../../src/components/research/resizable-graph";
import ResearchImage from "../../../../src/components/research/research-image";
import * as React from 'react';
export default {
  QualityOfSchoolingSVGMobile,
  QualityOfSchoolingSVGTable,
  QualityOfSchoolingSVGDesktop,
  ResizableGraph,
  ResearchImage,
  React
};