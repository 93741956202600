import ResearchImage from "../../../../src/components/research/research-image";
import GapsInAttainmentExpectedStandardDesktop from "../../../../src/content/school-years/svg/school-years-gaps-in-attainment-graph-1-desktop.svg";
import GapsInAttainmentExpectedStandardMobile from "../../../../src/content/school-years/svg/school-years-gaps-in-attainment-graph-1-mobile.svg";
import GapsInGCSEAttainmentStandardDesktop from "../../../../src/content/school-years/svg/school-years-gaps-in-gcse-attainment-graph-1-desktop.svg";
import GapsInGCSEAttainmentStandardMobile from "../../../../src/content/school-years/svg/school-years-gaps-in-gcse-attainment-graph-1-mobile.svg";
import GapsInAttainmentHigherStandardDesktop from "../../../../src/content/school-years/svg/school-years-gaps-in-attainment-graph-2-desktop.svg";
import GapsInAttainmentHigherStandardMobile from "../../../../src/content/school-years/svg/school-years-gaps-in-attainment-graph-2-mobile.svg";
import GapsInAttainmentMarginalReturnsDesktop from "../../../../src/content/school-years/svg/school-years-gaps-in-attainment-graph-3-desktop.svg";
import GapsInAttainmentMarginalReturnsMobile from "../../../../src/content/school-years/svg/school-years-gaps-in-attainment-graph-3-mobile.svg";
import ResizableGraph from "../../../../src/components/research/resizable-graph";
import GraphLegend from "../../../../src/components/research/graph-legend";
import * as React from 'react';
export default {
  ResearchImage,
  GapsInAttainmentExpectedStandardDesktop,
  GapsInAttainmentExpectedStandardMobile,
  GapsInGCSEAttainmentStandardDesktop,
  GapsInGCSEAttainmentStandardMobile,
  GapsInAttainmentHigherStandardDesktop,
  GapsInAttainmentHigherStandardMobile,
  GapsInAttainmentMarginalReturnsDesktop,
  GapsInAttainmentMarginalReturnsMobile,
  ResizableGraph,
  GraphLegend,
  React
};