import ResearchImage from "../../../../src/components/research/research-image";
import ApprenticeshipsDesktop from "../../../../src/content/further-education/svg/apprenticeships-desktop.svg";
import ApprenticeshipsMobile from "../../../../src/content/further-education/svg/apprenticeships-mobile.svg";
import ApprenticeshipsTablet from "../../../../src/content/further-education/svg/apprenticeships-tablet.svg";
import ResizableGraph from "../../../../src/components/research/resizable-graph";
import * as React from 'react';
export default {
  ResearchImage,
  ApprenticeshipsDesktop,
  ApprenticeshipsMobile,
  ApprenticeshipsTablet,
  ResizableGraph,
  React
};